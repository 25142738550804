import React from 'react'
import Grid from '@material-ui/core/Grid';
import './VideoContent.scss'


function VideoContent(props) {
  return (
    <Grid container spacing={8} direction="row" alignContent="stretch" justify="space-around" className="video-content" >
      <Grid item xs={12} style={{padding: 0}}>
        <div className="title">{props.title}</div>
      </Grid>
      <Grid item xs={12} className="video" style={{padding: 0}}>
        <VideoArea {...props.video}/>
      </Grid>
    </Grid>
  )
}

function VideoArea(videoInfo) {
  return (
    <div className="video-area">
      <div className="youtube-player-container" dangerouslySetInnerHTML={{__html: videoInfo.youtubePlayer}}/>
    </div>
  )
}

export default VideoContent;