import React, { Component } from "react";
import { connect } from 'react-redux'
import { PageWrapper, VideoContent } from '../../components'
import { releaseMenuChange } from '../../store/actions'
import releaseInfo from '../music-info'
import './VideoPage.scss'

export class VideoPage extends Component {
  render() {
    return (
      <PageWrapper content={content(this.props)} fullScreen={true}/>
    );
  }
}

const content = (props) => {
  const Padding = () => (<div className="padding-div"></div>)
  return(
    <div className="video-page-container">
      <VideoContent title="Death And THe Early House" video={releaseInfo['DATEH'].video} className="DATEH"/>
      <Padding />
      <VideoContent title="To Reconcile" video={releaseInfo['ToRec'].video} className="ToRec"/>
      <Padding />
      <VideoContent title="Border Boys" video={releaseInfo['BB'].video} className="BB"/>
      <Padding />
      <VideoContent title="Get Worse" video={releaseInfo['GW'].video} className="GW" />
      <Padding />
      <VideoContent title="22:22" video={releaseInfo['II-II'].video} className="II-II"/>
    </div>
)}

const mapStateToProps = state => {
  return {
    showBB: state.release.showBB,
    showGW: state.release.showGW,
    showII: state.release.showII
  }
}

const mapDispatchToProps = dispatch => {
  return {
    menuChange: (page) => (show) => dispatch(releaseMenuChange(show, page))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoPage)