import React from 'react'
import Grid from '@material-ui/core/Grid';
import './EventItem.scss'

function EventItem(props) {
    return (
      <Grid container direction="row" alignContent="stretch" justify="space-between" className="event-item" >
        <Grid item xs={3} md={2} className="item date">
          <span>{props.date}</span>
        </Grid>
        <Grid item xs={9} md={5} className="item venue">
          <span>{props.venue}</span>
        </Grid>
        <Grid item xs={6} md={3} className="item location">
          <span>{props.location}</span>
        </Grid>
        <Grid item xs={3} md={1} className="item event-info">
          <a target="_blank" href={props.eventLink}>INFO</a>
        </Grid>
        <Grid item xs={3} md={1} className="item ticket-link">
          <a target="_blank" href={props.ticketLink}>TICKETS</a>
        </Grid>
        <Grid item xs={12} className="border">
        </Grid>
      </Grid>
    )
}


export default EventItem