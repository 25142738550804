import React, { Component } from "react";
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { showMobileMenu } from '../../store/actions'
import Grid from '@material-ui/core/Grid'
import { LogoWhite } from '../index'
import MenuIcon from '@material-ui/icons/Menu'
import './Menu.scss'

export class Menu extends Component {

  render() {
    if (!this.props.show) {
      return (<div></div>)
    }
    return (
      <div className="menu">
        <Grid container spacing={0} direction="row" justify="space-between" className={`menu-container ${this.props.isLanding ? 'landing' : ''} ${this.props.showMobile ? 'active' : ''}`}>
          <Grid item xs={1} className="contain">
            <div className={`menu-logo ${this.props.isLanding ? 'hide' : ''}`} onClick={() => this.props.route('/')}>
              <LogoWhite />
            </div>
          </Grid>
          <Grid item xs={11} className={`menu-links ${this.props.isLanding ? 'hide' : ''}`}>
            <div className={`menu-link ${this.props.isLanding ? 'hide' : ''}`} onClick={() => this.props.route('music')}>
              <span className={`${this.props.url === '/music' ? 'active' : ''}`}>MUSIC</span>
            </div>
            <div className={`menu-link ${this.props.isLanding ? 'hide' : ''}`} onClick={() => this.props.route('video')}>
              <span className={`${this.props.url === '/video' ? 'active' : ''}`}>VIDEO</span>
            </div>
            <div className={`menu-link ${this.props.isLanding ? 'hide' : ''}`} onClick={() => this.props.route('live')}>
              <span className={`${this.props.url === '/live' ? 'active' : ''}`}>LIVE</span>
            </div>
            <div className={`menu-link ${this.props.isLanding ? 'hide' : ''}`} onClick={() => this.props.route('contact')}>
              <span className={`${this.props.url === '/contact' ? 'active' : ''}`}>CONTACT</span>
            </div>
            <div className={`menu-link ${this.props.isLanding ? 'hide' : ''}`}>
              <a href="https://owlboyrecords.bigcartel.com/products" target="_blank" rel="noopener noreferrer">
                <span className={`${this.props.url === '/shop' ? 'active' : ''}`}>SHOP</span>
              </a>
            </div>
            <div className={`menu-mobile ${this.props.showMobile ? 'active' : ''}`} onClick={() => this.props.showMobileMenu(!this.props.showMobile)}>
              <MenuIcon />
              <div className="menu-mobile-container">
                <div className="menu-mobile-link" onClick={() => this.props.route('music')}>
                  <span className={`${this.props.url === '/music' ? 'active' : ''}`}>MUSIC</span>
                </div>
                <div className="menu-mobile-link" onClick={() => this.props.route('video')}>
                  <span className={`${this.props.url === '/video' ? 'active' : ''}`}>VIDEO</span>
                </div>
                <div className="menu-mobile-link" onClick={() => this.props.route('live')}>
                  <span className={`${this.props.url === '/live' ? 'active' : ''}`}>LIVE</span>
                </div>
                <div className="menu-mobile-link" onClick={() => this.props.route('contact')}>
                  <span className={`${this.props.url === '/contact' ? 'active' : ''}`}>CONTACT</span>
                </div>
                <div className="menu-mobile-link">
                  <a href="https://owlboyrecords.bigcartel.com/products" target="_blank" rel="noopener noreferrer">
                    <span className={`${this.props.url === '/shop' ? 'active' : ''}`}>SHOP</span>
                  </a>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    show: state.menu.show,
    showMobile: state.menu.showMobile
  }
}

const mapDispatchToProps = dispatch => {
  return {
    route: (pathname) => dispatch(push({ pathname })),
    showMobileMenu: (value) => dispatch(showMobileMenu(value))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Menu)