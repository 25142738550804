export default []

// export default [{
//   date: "16 / 04",
//   venue: "Róisín Dubh (Upstairs)",
//   location: "Galway, IRL",
//   eventLink: "https://www.facebook.com/events/130754501689407/",
//   ticketLink: "https://www.roisindubh.net/gig-details.html?listingID=10648&fbclid=IwAR3aUATWcM9I5mlUvkSrQnxqtXmWig_er4MI4BjNC8-j7ryVG4nRbXcv8Lc"
// }, {
//   date: "17 / 04",
//   venue: "Kino",
//   location: "Cork, IRL",
//   eventLink: "https://www.facebook.com/events/206682060737974/",
//   ticketLink: "https://uticket.ie/event/fields-kino?fbclid=IwAR1PfheDDYpH3Z29jZUcuT9HlKq4GD-jFYCge2jKD0NbQDgyehRIhmGzh_k"
// }, {
//   date: "18 / 04",
//   venue: "St. Peter's Tin Church",
//   location: "Monaghan, IRL",
//   eventLink: "https://www.facebook.com/events/2312496382380964/",
//   ticketLink: "https://www.eventbrite.com/e/fields-st-peters-tin-church-tickets-98382766503?fbclid=IwAR2HvybBkGVWSdqF5tKToZtvJ9sPMjwObWmPh-PHxh4nuZTSt7k_T1RAZp8"
// }, {
//   date: "23 / 04",
//   venue: "Whelans (Main Room)",
//   location: "Dublin, IRL",
//   eventLink: "https://www.facebook.com/events/565639984044026/",
//   ticketLink: "http://www.whelanslive.com/index.php/wav-tickets/"
// }]

// const pastEvents = [{
//   date: "26 / 10",
//   venue: "Whelans (Main Room)",
//   location: "Dublin, IRL",
//   eventLink: "https://www.facebook.com/events/530247127542963/",
//   ticketLink: "http://www.whelanslive.com/index.php/wav-tickets/"
// }, {
//   date: "29 / 11",
//   venue: "Workman's Club (Vintage Room)",
//   location: "Dublin, IRL",
//   eventLink: "https://www.facebook.com/events/2185137544921461/",
//   ticketLink: "https://www.eventbrite.com/e/fields-live-at-the-workmans-club-vintage-room-tickets-75586598551?fbclid=IwAR2XE7pBgxfxc2S-m60lz5nVyc2OQBKCSDp4QpsVkzNBuwSYAE9hX8Tdi4U"
// }]