import React from 'react'
import Grid from '@material-ui/core/Grid';
import { SocialIcon } from 'react-social-icons';
import './SocialIcons.scss'

function SocialIcons() {
  return (
      <div className="social-icons">
        <Grid container spacing={0} alignItems="center" justify="space-between" alignContent="center" className="social-icons-grid">
          <Grid item xs={12} className="social-icons-button">
              <Grid container spacing={0} alignItems="center" justify="space-between" alignContent="center" className="icons">  
                <Grid item xs={2} className="social-icons-item social-item">
                  <SocialIcon url="https://open.spotify.com/artist/59EbnytPtNzdW8tXcPadu4" />
                </Grid>
                <Grid item xs={2} className="social-icons-item social-item">
                  <SocialIcon url="https://itunes.apple.com/ie/artist/fields/1056294360" />
                </Grid>
                <Grid item xs={2} className="social-icons-item social-item">
                  <SocialIcon url="https://www.youtube.com/channel/UC_qsLponLASXyiomNVNw_ng" />
                </Grid>
                <Grid item xs={2} className="social-icons-item social-item">
                  <SocialIcon url="https://www.instagram.com/fieldstheboys" />
                </Grid>
                <Grid item xs={2} className="social-icons-item social-item">
                  <SocialIcon url="https://www.facebook.com/fieldstheboys/" />
                </Grid>
                <Grid item xs={2} className="social-icons-item social-item">
                  <SocialIcon url="https://twitter.com/fieldstheboys" />
                </Grid>
              </Grid>
          </Grid>
        </Grid>
      </div>
  )
}

export default SocialIcons