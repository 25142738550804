import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid';
import Footer from '../footer'
import './PageWrapper.scss'

export class PageWrapper extends Component {

  render() {
    return (
      <div className={`page-wrapper ${this.props.className}`}>
        <Grid container direction="row" alignContent="stretch">
          <Grid item xs={12} className="page-section full-screen">
            {this.props.content}
          </Grid>
          <Grid item xs={12} className="footer-grid">
            <Footer />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export function TextPanel(props) {
  return (
    <div className="text-panel">
      {props.text}
    </div>
  )
}

export default PageWrapper