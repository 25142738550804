import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Switch, Route } from "react-router-dom"
import { LandingPage, ContactPage, MusicPage, VideoPage, LivePage, TCPage } from './containers'
import { showMenu, showMobileMenu, showPopUp } from './store/actions'
import { Menu, Footer, PopUp} from './components'
import CookieConsent, { Cookies } from "react-cookie-consent";
import './App.scss'

class App extends Component {

  render() {
    const { location, popUp, showPU } = this.props
    let isLanding = true
    this.props.hideMobileMenu()
    if (location.pathname !== '' && location.pathname !== '/') {
      isLanding = false
    }
    return (
      <div className="App">
        <header><Menu isLanding={isLanding} url={location.pathname}/></header>
        <LandingPage isLanding={isLanding} />
        <Switch location={location}>
          <Route path="/music" component={MusicPage} />
          <Route path="/video" component={VideoPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/live" component={LivePage} />
          <Route path="/tc" component={TCPage} />
        </Switch>
        {isLanding ? <Footer className="landing"/> : <div /> }
        {/* <PopUp open={popUp} onClose={() => showPU(false)}/> */}
        <CookieConsent>
          This website uses cookies to enhance the user experience. <a href="/tc">T&amp;C's</a>
        </CookieConsent>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    location: state.router.location,
    popUp: state.menu.popUp
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showMenu: (value) => dispatch(showMenu(value)),
    route: (pathname) => dispatch(push({ pathname })),
    hideMobileMenu: () => dispatch(showMobileMenu(false)),
    showPU: (show) => dispatch(showPopUp(show))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App)
