export default {
  BB: {
    video: {
      youtubePlayer: "<iframe width='100%' height='100%' src='https://www.youtube.com/embed/Dni3_vCzHO8' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
    },
    musicInfo: {
      links: [
        {
          listenUrl: "https://open.spotify.com/album/57nwnotZtqX2nJewvMrU3b",
          listenType: "spotify"
        },{
          listenUrl: "https://itunes.apple.com/bb/album/border-boys/1457075749?i=1457075750&?app=music",
          listenType: "itunes"
        },{
          listenUrl: "https://play.google.com/store/music/album/FIELDS_Border_Boys?id=B3m2c7352fthrceoxoksqqz2xry&hl=en",
          listenType: "google-play"
        },{
          listenUrl: "https://www.deezer.com/us/track/652266942",
          listenType: "deezer"
        },{
          listenUrl: "https://www.amazon.com/Border-Boys-Fields/dp/B07PVKDZJV/ref=sr_1_1?keywords=fields+border+boys&qid=1554850944&s=dmusic&sr=1-1-catcorr",
          listenType: "amazon"
        },{
          listenUrl: "https://tidal.com/browse/album/106282012",
          listenType: "tidal"
        }
      ]
    }
  },
  GW: {
    video: {
      youtubePlayer: "<iframe width='100%' height='100%' src='https://www.youtube.com/embed/VTaba4uvpA8' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
    },
    musicInfo: {
      links: [
        {
          listenUrl: "https://open.spotify.com/album/2XFoxLzvhOdod5HyWCToZC?si=DiEBe1iHQZ6YFrZ8wUai2g",
          listenType: "spotify"
        },{
          listenUrl: "https://itunes.apple.com/ie/album/get-worse-single/1432727089?app=music&ign-mpt=uo%3D4",
          listenType: "itunes"
        },{
          listenUrl: "https://play.google.com/store/music/album/FIELDS_Get_Worse?id=Bo4qegvh56cinwzqgrbfzuumnrm&hl=en",
          listenType: "google-play"
        },{
          listenUrl: "https://www.deezer.com/us/album/71298232",
          listenType: "deezer"
        },{
          listenUrl: "https://www.amazon.com/Get-Worse-Fields/dp/B07GR4JBVT/ref=sr_1_1?s=dmusic&ie=UTF8&qid=1536280406&sr=1-1-mp3-albums-bar-strip-0&keywords=fields+get+worse",
          listenType: "amazon"
        },{
          listenUrl: "https://tidal.com/browse/album/93965769",
          listenType: "tidal"
        }
      ]
    }
  },
  SK: {
    musicInfo: {
      links: [
        {
          listenUrl: "https://open.spotify.com/album/2LFvoF3Qm74DKPUIlVBRbQ",
          listenType: "spotify"
        },{
          listenUrl: "https://itunes.apple.com/bb/album/some-kids-single/1377660634",
          listenType: "itunes"
        },{
          listenUrl: "https://play.google.com/store/music/album?id=Bnhdhjek2t3d6r3snnk3pdmmqai&tid=song-T7bhzm2qsckfmz6f4mrefozkmhm&hl=en",
          listenType: "google-play"
        },{
          listenUrl: "https://www.deezer.com/us/album/62482192",
          listenType: "deezer"
        },{
          listenUrl: "https://www.amazon.com/Some-Kids-Fields/dp/B07CQQQG7V/ref=sr_1_4?keywords=fields+some+kids&qid=1556045442&s=dmusic&sr=1-4-catcorr",
          listenType: "amazon"
        },{
          listenUrl: "https://tidal.com/browse/track/88183909",
          listenType: "tidal"
        }
      ]
    }
  },
  "II-II": {
    video: {
      youtubePlayer: "<iframe width='100%' height='100%' src='https://www.youtube.com/embed/OQV0x7dBPnM' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
    },
    musicInfo: {
      links: [
        {
          listenUrl: "https://open.spotify.com/album/5Epj2ZQl9hCYpUZ9IpefOJ",
          listenType: "spotify"
        },{
          listenUrl: "https://itunes.apple.com/ie/album/22-22-single/1209575366",
          listenType: "itunes"
        },{
          listenUrl: "https://play.google.com/store/music/album?id=Bitazzgbixpa2mjtzkmhxv5fbj4&tid=song-T7bqo7xijewvmd4tnyqre5egxy4&hl=en",
          listenType: "google-play"
        },{
          listenUrl: "https://www.deezer.com/us/album/15469190",
          listenType: "deezer"
        },{
          listenUrl: "https://www.amazon.com/22-Fields/dp/B06XD3MB9Z/ref=sr_1_4?keywords=fields+22%3A22&qid=1555621516&s=dmusic&sr=1-4",
          listenType: "amazon"
        },{
          listenUrl: "https://tidal.com/browse/album/70869925",
          listenType: "tidal"
        }
      ]
    }
  },
  ITM: {
    musicInfo: {
      links: [
        {
          listenUrl: "https://open.spotify.com/album/4oU86yZRyx9xfmlwlnW7pK",
          listenType: "spotify"
        },{
          listenUrl: "https://itunes.apple.com/bb/album/in-the-morning-ep/1057302075",
          listenType: "itunes"
        },{
          listenUrl: "https://play.google.com/store/music/album/FIELDS_In_the_Morning?id=Bejuz7gendhguvfqg2vpzsindfy&hl=en",
          listenType: "google-play"
        },{
          listenUrl: "https://www.deezer.com/us/album/11724236",
          listenType: "deezer"
        },{
          listenUrl: "https://www.amazon.com/Morning-Fields/dp/B0181WP22Y/ref=sr_1_6?keywords=fields+in+the+morning&qid=1556045640&s=dmusic&sr=1-6",
          listenType: "amazon"
        },{
          listenUrl: "https://tidal.com/browse/album/54213589",
          listenType: "tidal"
        }
      ]
    }
  },
  ToRec: {
    video: {
      youtubePlayer: "<iframe width='100%' height='100%' src='https://www.youtube.com/embed/qpE51jr8nwk' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
    },
    musicInfo: {
      links: [
        {
          listenUrl: "http://open.spotify.com/album/556ROyTYjncfJSV0M9yIWq",
          listenType: "spotify"
        },{
          listenUrl: "https://music.apple.com/us/album/to-reconcile-single/1482821973?ls=1",
          listenType: "itunes"
        },{
          listenUrl: "https://play.google.com/store/music/album/FIELDS_To_Reconcile?id=Be4sxua4knpinaysilbkjmd6ajq&hl=en",
          listenType: "google-play"
        },{
          listenUrl: "https://www.deezer.com/en/album/114183252",
          listenType: "deezer"
        },{
          listenUrl: "https://www.amazon.com/Reconcile-Fields/dp/B07YVNWXJ8/ref=sr_1_2?keywords=fields+to+reconcile&qid=1573776388&sr=8-2",
          listenType: "amazon"
        }
      ]
    }
  },
  DATEH: {
    video: {
      youtubePlayer: "<iframe width='100%' height='100%' src='https://www.youtube.com/embed/sAjjyFQBh-s' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
    },
    musicInfo: {
      links: [
        {
          listenUrl: "https://open.spotify.com/album/4yDnw8sahOa2bLlrvI9V8f",
          listenType: "spotify"
        },{
          listenUrl: "https://music.apple.com/ie/album/death-and-the-early-house-single/1497939411?app=music",
          listenType: "itunes"
        },{
          listenUrl: "https://play.google.com/store/music/album/FIELDS_Death_and_the_Early_House?id=Bsso6htjuyhtyl3y3a236sdhogq&hl=en",
          listenType: "google-play"
        },{
          listenUrl: "https://www.deezer.com/us/album/130423562",
          listenType: "deezer"
        },{
          listenUrl: "https://music.amazon.com/albums/B084HNNGSJ?tag=featurefm-20",
          listenType: "amazon"
        }
      ]
    }
  },
  LINCOLN: {
    video: {
      youtubePlayer: ""
    },
    musicInfo: {
      links: [
        {
          listenUrl: "https://open.spotify.com/album/4Y97UUoa99EdLuQnVdJ6m9",
          listenType: "spotify"
        },{
          listenUrl: "https://music.apple.com/ie/album/1501746417?app=music",
          listenType: "itunes"
        },{
          listenUrl: "https://play.google.com/store/music/album?id=B3b7dlssdvoltkcpdfukwsy73a4&tid=song-Tibkeh4hpixcosywmn3fey24llq&hl=en",
          listenType: "google-play"
        },{
          listenUrl: "https://www.deezer.com/us/album/135052882",
          listenType: "deezer"
        },{
          listenUrl: "https://music.amazon.com/albums/B085K7K19J",
          listenType: "amazon"
        }
      ]
    }
  },
  TSOSI: {
    musicInfo: {
      // preOrder: {
      //   link: "https://owlboyrecords.bigcartel.com/products",
      //   releaseDate: "17/04/2020"
      // },
      links: [
        {
          listenUrl: "https://open.spotify.com/album/2NCYWlT5BxaK6MrKCeWh4b",
          listenType: "spotify"
        },{
          listenUrl: "https://music.apple.com/ie/album/the-silence-of-staying-in/1501558016",
          listenType: "itunes"
        },{
          listenUrl: "https://play.google.com/store/music/album/FIELDS_The_Silence_Of_Staying_In?id=Bkzkwospkfpqugy3xqrusnhamwm&hl=en",
          listenType: "google-play"
        },{
          listenUrl: "https://www.deezer.com/us/album/134817582",
          listenType: "deezer"
        },{
          listenUrl: "https://music.amazon.com/albums/B085GLV7NL?tab=CATALOG",
          listenType: "amazon"
        }
      ]
    }
  }
}