import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from './store'
import App from './App'
import './index.scss';

const store = configureStore()

// hack for android bottom menu
function setUpPageheight() {
    let vh = window.innerHeight * 0.01;
    let vhSub = 140 * 0.01;
    let vhSubMob = 300 * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--height-sub', `${vhSub}px`);
    document.documentElement.style.setProperty('--height-sub-mob', `${vhSubMob}px`);
}
setUpPageheight()
window.addEventListener('resize', () => {setUpPageheight()})

ReactDOM.render(
  <Provider store={store}>
      <ConnectedRouter history={history}>
          <BrowserRouter>
              <App />
          </BrowserRouter>
      </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)
