import { MENU_SHOW, MENU_MOBILE_SHOW, MENU_SOCIAL_SHOW, POP_UP } from '../actions/action.types'

const initialState = {
  show: true,
  showMobile: true,
  popUp: true,
  social: {
    show: false
  }
};

export default function menuReducer(state = initialState, action) {
  switch(action.type) {
    case MENU_SHOW:
      return {
        ...state,
        show: action.show
      };
    case MENU_MOBILE_SHOW:
      return {
        ...state,
        showMobile: action.show
      };
    case MENU_SOCIAL_SHOW:
      return {
        ...state,
        social: {
          ...state.social,
          show: action.show
        }
      };
    case POP_UP:
      return {
        ...state,
        popUp: action.show
      };

    default:
      return state;
  }
}