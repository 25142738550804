import React, { Component } from "react";
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Grid from '@material-ui/core/Grid';
import { Logo } from '../../components'
import './LandingPage.scss'

export class LandingPage extends Component {

  render() {
    if (!this.props.isLanding) {
      return (<div></div>)
    }
    return (
      <div className="menu">
        <div className={"landing-page-container"}>
          <Grid container spacing={24} alignItems="center" justify="space-around" alignContent="center" className="landing-grid">
            <Grid item xs={8} xl={10} className="landing-logo">
              <Logo />
            </Grid>
            <Grid item xs={8} xl={10} className="landing-menu-grid">
              <LandingMenu route={(path) => this.props.route(path)}/>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const LandingMenu = (props) => {
  return(
    <div className="landing-menu">
      <Grid container spacing={24} direction="row" justify="space-around">
        <div className="menu-link" onClick={() => props.route('music')}>MUSIC</div>
        <div className="menu-link" onClick={() => props.route('video')}>VIDEO</div>
        <div className="menu-link" onClick={() => props.route('live')}>LIVE</div>
        <div className="menu-link" onClick={() => props.route('contact')}>CONTACT</div>
        <a href="https://owlboyrecords.bigcartel.com/products" target="_blank" rel="noopener noreferrer">
          <div className="menu-link">SHOP</div>
        </a>
      </Grid>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    show: !state.menu.show
  }
}

const mapDispatchToProps = dispatch => {
  return {
    route: (pathname) => dispatch(push({ pathname , state: { social: { hide: true}}}))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(LandingPage)