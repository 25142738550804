import React, { Component } from "react";
import { connect } from 'react-redux'
import { PageWrapper, EventItem } from '../../components'
import eventData from './events'
import './LivePage.scss'

export class LivePage extends Component {
  render() {
    return (
      <PageWrapper content={content()} className="live-page" />
    );
  }
}

const content = () => {
  return (
    <div className="live-page-container">
      <div className="title">live</div>
      {eventData.map(event => <EventItem {...event}/> )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(LivePage)