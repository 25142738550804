import React from 'react'
import './Logo.scss'

function Logo() {
    return (
        <div className="logo">
            <img src="/images/logo.png" style={{width: '100%'}} border="0"  alt="logo"/>
        </div>
    )
}

export const LogoWhite = () => {
    return (
        <div className="logo-white">
            <img src="/images/logo-white.png" style={{height: '100%'}} border="0"  alt="logo"/>
        </div>
    )
}

export default Logo