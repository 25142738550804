import { RELEASE_MENU_CHANGE } from '../actions/action.types'

const initialState = {
  show: 'LISTEN',
  showBB: 'LISTEN',
  showGW: 'LISTEN',
  showII: 'LISTEN'
};

export default function menuReducer(state = initialState, action) {
  console.log('action', action)
  switch(action.type) {
    case RELEASE_MENU_CHANGE + 'BB':
      return {
        ...state,
        showBB: action.show
      };
    case RELEASE_MENU_CHANGE + 'GW':
      return {
        ...state,
        showGW: action.show
      };
    case RELEASE_MENU_CHANGE + 'II':
      return {
        ...state,
        showII: action.show
      };

    default:
      return state;
  }
}