import React, { Component } from "react";
import { connect } from 'react-redux'
import { PageWrapper, MusicContent } from '../../components'
import { releaseMenuChange } from '../../store/actions'
import releaseInfo from '../music-info'
import './MusicPage.scss'

export class MusicPage extends Component {
  render() {
    return (
      <PageWrapper content={content()} fullScreen={true}/>
    );
  }
}

const content = () => {
  const Padding = () => (<div className="padding-div"></div>)
  return(
    <div className="music-page-container">
      <MusicContent artwork="/images/TSOSI.jpg" title="The Silence Of Staying In" listen={releaseInfo['TSOSI'].musicInfo} className="TSOSI"/>
      <Padding />
      <MusicContent artwork="/images/Lincoln.jpg" title="Lincoln" listen={releaseInfo['LINCOLN'].musicInfo} className="Lincoln"/>
      <Padding />
      <MusicContent artwork="/images/DATEH.jpg" title="Death And The Early House" listen={releaseInfo['DATEH'].musicInfo} className="DATEH"/>
      <Padding />
      <MusicContent artwork="/images/to-rec-artwork.jpg" title="To Reconcile" listen={releaseInfo['ToRec'].musicInfo} className="ToRec"/>
      <Padding />
      <MusicContent artwork="/images/BB.jpg" title="Border Boys" listen={releaseInfo['BB'].musicInfo} className="BB"/>
      <Padding />
      <MusicContent artwork="/images/GW.jpg" title="Get Worse" listen={releaseInfo['GW'].musicInfo} className="GW"/>
      <Padding />
      <MusicContent artwork="/images/SK.jpg" title="Some Kids" listen={releaseInfo['SK'].musicInfo} className="SK"/>
      <Padding />
      <MusicContent artwork="/images/22-22.jpg" title="22:22" listen={releaseInfo['II-II'].musicInfo} className="II-II"/>
      <Padding />
      <MusicContent artwork="/images/ITM.jpg" title="In The Morning - EP" listen={releaseInfo['ITM'].musicInfo} className="ITM"/>
    </div>
)}

const mapStateToProps = state => {
  return {
    showBB: state.release.showBB,
    showGW: state.release.showGW,
    showII: state.release.showII
  }
}

const mapDispatchToProps = dispatch => {
  return {
    menuChange: (page) => (show) => dispatch(releaseMenuChange(show, page))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MusicPage)