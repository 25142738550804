import { MENU_SHOW, MENU_MOBILE_SHOW, MENU_SOCIAL_SHOW, POP_UP } from './action.types'

export const showMenu = show => ({
  type: MENU_SHOW,
  show
});

export const showMobileMenu = show => ({
  type: MENU_MOBILE_SHOW,
  show
});

export const showSocialMenu = show => ({
  type: MENU_SOCIAL_SHOW,
  show
});

export const showPopUp = show => ({
  type: POP_UP,
  show
});
