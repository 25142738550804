import React from 'react'
import SocialIcons from '../social-icons'
import Grid from '@material-ui/core/Grid'
import './Footer.scss'

function Footer(props = {}) {
    return (
      <Grid container direction="row" alignContent="stretch" justify="space-around" className={`footer ${props.className}`} >
        <Grid item xs={12} sm={6} className="footer-copyright">
            <div className="copyright">&copy;2020 <span>owl boy records </span><a href="/tc">T&amp;C's</a></div>
        </Grid>
        <Grid item xs={12} sm={6} className="footer-icons">
          <SocialIcons />
        </Grid>
      </Grid>
    )
}

export default Footer