import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MusicContent from '../music-content'
import releaseInfo from '../../containers/music-info'
import './PopUp.scss'

function PopUp(props) {
  return (
    <div>
      <Dialog onClose={props.onClose} aria-labelledby="customized-dialog-title" open={props.open}>
        <MusicContent artwork="/images/TSOSI.jpg" title="The Silence Of Staying In" listen={releaseInfo['TSOSI'].musicInfo} className="TSOSI"/>
        <IconButton aria-label="close" onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
        {/* <MuiDialogTitle>
          <IconButton aria-label="close" onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent>
          <MusicContent artwork="/images/TSOSI.jpg" title="The Silence Of Staying In" listen={releaseInfo['TSOSI'].musicInfo} className="TSOSI"/>
        </MuiDialogContent> */}
      </Dialog>
    </div>
  );
}

export default PopUp