import React, { Component } from "react";
import { connect } from 'react-redux'
import { PageWrapper } from '../../components'
import Grid from '@material-ui/core/Grid';
import './ContactPage.scss'

export class ContactPage extends Component {
  render() {
    return (
      <PageWrapper content={content()} className="contact-page" />
    );
  }
}

const content = () => {
  return (
    <div className="contact-page-container">
      <div className="title">contacts</div>
      <Grid container spacing={8} direction="row" alignContent="stretch" justify="space-around" className="contact-page-grid" >
        <Grid item xs={12} className="item">
          <div className="email-text">Bookings</div>
        </Grid>
        <Grid item xs={12} className="item">
          <a className="email" href="mailto: artists@owlboycollective.com">artists@owlboycollective.com</a>
        </Grid>
      </Grid>
      <Grid container spacing={8} direction="row" alignContent="stretch" justify="space-around" className="contact-page-grid" >
        <Grid item xs={12} className="item">
          <div className="email-text">General Enquiries</div>
        </Grid>
        <Grid item xs={12} className="item">
        <a className="email" href="mailto: sean@owlboycollective.com">sean@owlboycollective.com</a>
        </Grid>
      </Grid>
      <Grid container spacing={8} direction="row" alignContent="stretch" justify="space-around" className="contact-page-grid" >
        <Grid item xs={12} className="item">
          <div className="email-text">NEWSLETTER</div>
        </Grid>
        <Grid item xs={12} className="item">
          <div className="news-letter">
            <p><a href="https://us19.campaign-archive.com/home/?u=a7f674a32d3766da7d1af4f2f&id=c3989c52ea" title="View previous campaigns">View past news letters.</a></p>
            <div id="mc_embed_signup">
              <form action="https://fieldstheboys.us19.list-manage.com/subscribe/post?u=a7f674a32d3766da7d1af4f2f&amp;id=c3989c52ea" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                <div id="mc_embed_signup_scroll">
                  {/* <h2>NEWSLETTER</h2> */}
                  <div className="mc-field-group">
                    <label htmlFor="mce-EMAIL">Email Address </label>
                    <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" />
                  </div>
                  <div id="mce-responses" className="clear">
                    <div className="response" id="mce-error-response" style={{display: 'none'}}></div>
                    <div className="response" id="mce-success-response" style={{display: 'none'}}></div>
                  </div>
                    <div style={{position: 'absolute', left: -5000}} aria-hidden="true"><input type="text" name="b_a7f674a32d3766da7d1af4f2f_c3989c52ea" tabIndex="-1" /></div>
                    <div className="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" /></div>
                </div>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ContactPage)