import React from 'react'
import Grid from '@material-ui/core/Grid';
import ReactSVG from 'react-svg'
import './MusicContent.scss'


function MusicContent(props) {
  return (
    <Grid container spacing={8} direction="row" alignContent="stretch" justify="space-around" className="release-content" >
      <Grid item xs={12} className="release-panel artwork-panel" style={{padding: 0}}>
        <div className="artwork-area">
          <img src={props.artwork} style={{width: '100%'}} border="0"  alt="logo"/>
        </div>
      </Grid>
      <Grid item xs={12} className="release-panel content-panel" style={{padding: 0, marginTop: -4}}>
        <div className="content-container">
          <div className="content-area">
            <Links {...props.listen} />
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

function Links(musicInfo){
  if (musicInfo.links) {
    return (
      <SocailIcons {...musicInfo}/>
    )
  }
  return(<PreOrder {...musicInfo.preOrder}/>)
}

function PreOrder(props) {
  if (!props.link) {
    return (
      <Grid container spacing={8} direction="row" alignContent="stretch" justify="space-around" className="preOrder-area" >
        <Grid item xs={12} className="po-item" style={{padding: 0}}>
          <div className='release-date item'>{props.releaseDate}</div>
        </Grid>
      </Grid>
    )
  }
  return(
    <Grid container className="preOrder-area" spacing={8} direction="row" alignItems="center" justify="center">
      <Grid item xs={12} md={6} className="po-item" style={{padding: 0}}>
        <div className='release-date item'>{props.releaseDate}</div>
      </Grid>
      <Grid item xs={12} md={6} className="po-item" style={{padding: 0}}>
        <div className='preOrder-link item'>
          <a href={props.link}>
            <div className='po-button'>{props.preSave? 'PRE-SAVE' : 'PRE-ORDER'}</div>
          </a>
        </div>
      </Grid>
    </Grid>
  )
}

function SocailIcons(musicInfo) {
  return (
    <div className='listen-area'>
      {/* <div className="player-container" dangerouslySetInnerHTML={{__html: musicInfo.player}}/> */}
      <Grid container className="listen-links" spacing={8} direction="row" alignItems="center" justify="center">
        {musicInfo.links.map((link, i) => {      
           return (
            <Grid key={i} item xs={4} className="item">
              <SocailIcon {...link} />
            </Grid>
           ) 
        })}
      </Grid>
    </div>
  )
}

function SocailIcon(props) {
  return (
    <div className="listen-icon">
      <a href={props.listenUrl} target="_blank" rel="noopener noreferrer">
        <ReactSVG src={`/images/${props.listenType}.svg`} className="icon"/>
      </a>
    </div>
  )
}

export default MusicContent;